// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-application-development-js": () => import("./../../../src/pages/application-development.js" /* webpackChunkName: "component---src-pages-application-development-js" */),
  "component---src-pages-application-maintenance-js": () => import("./../../../src/pages/application-maintenance.js" /* webpackChunkName: "component---src-pages-application-maintenance-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-management-js": () => import("./../../../src/pages/project-management.js" /* webpackChunkName: "component---src-pages-project-management-js" */),
  "component---src-pages-staff-augmentation-js": () => import("./../../../src/pages/staff-augmentation.js" /* webpackChunkName: "component---src-pages-staff-augmentation-js" */),
  "component---src-pages-testing-services-js": () => import("./../../../src/pages/testing-services.js" /* webpackChunkName: "component---src-pages-testing-services-js" */),
  "component---src-pages-ui-ux-design-js": () => import("./../../../src/pages/ui-ux-design.js" /* webpackChunkName: "component---src-pages-ui-ux-design-js" */)
}

